<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                 
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>                 
                    <div class="papers">
                        <div class="year">
                            <h4>2022</h4>
                            <a href="https://drive.google.com/drive/folders/1lE9yY-AwBaCQeFHA33TTD5L8iTqsVF-3?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2021</h4>
                            <a href="https://drive.google.com/drive/folders/1msuBOTj8dI89hLscKMVe0hyzj8N622WB?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2020</h4>
                            <a href="https://drive.google.com/drive/folders/1TOJZU6-VwO71VmLhL1SN13Tqvp2gANWS?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2019</h4>
                            <a href="https://drive.google.com/drive/folders/1jhLECLlHoBcGfjciEPZPhJbXR1YNLIl1?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2018</h4>
                            <a href="https://drive.google.com/drive/folders/1z45cDlxe3zc1Zxvy520a_2Y_bAk8jN4m?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2017</h4>
                            <a href="https://drive.google.com/drive/folders/1tHt6QPJP2j3_EfpYArJocmAAi3cAojX3?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2016</h4>
                            <a href="https://drive.google.com/drive/folders/1Ei1mP39KlwCyHt4xVQCZXUDGOqJQz2Z0?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2015</h4>
                            <a href="https://drive.google.com/drive/folders/1EYbY8q2QiZnVsnpb6W0ZnqpNvQKxEbDp?usp=sharing" target="_blank">View</a>
                        </div>
                       
                        <div class="year">
                            <h4>2013</h4>
                            <a href="https://drive.google.com/drive/folders/1c3GWvLLG1BUfLvPI5VPaQ3WJkMmWCqNa?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2012</h4>
                            <a href="https://drive.google.com/drive/folders/1UckR6xF3eRmcnBaSBaAZe9R87acmlrC0?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2011</h4>
                            <a href="https://drive.google.com/drive/folders/1803yQwMLyevq6zkgHXyamao-ZjXeJPks?usp=sharing" target="_blank">View</a>
                        </div>
                        <div class="year">
                            <h4>2010</h4>
                            <a href="https://drive.google.com/drive/folders/1z7jPs84_R6e62-YS_tu1Hwk5EUj4I6yO?usp=sharing" target="_blank">View</a>
                        </div>
                    </div>
                    <hr>
                    <a class="button" href="https://drive.google.com/drive/folders/1kNOnmVTJfo6thKZ74cJqfoOrWq3T8HiG?usp=sharing" target="_blank">View All</a>                  
              </div>
              
          <div class="sidebar">
              <NonAcademicQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';

import Footer from "../../components/page/Footer.vue";





export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts,Footer },
    setup(){
        const title= ref('Past Question Papers')
        return {title}
    }
  
}
</script>

<style scoped>
.page-content .button{
    text-decoration: none;
    color: #111;
    margin-top: 1rem;
    transition: .5s ease all;
}
.page-content .button:hover{
    color: var(--primary);
}
.papers{
    display: grid;
    grid-template-columns: auto auto;
}
.papers .year{
    display: flex;
    justify-content: space-between;
    background: #ececec;
    padding: 0.3rem 1rem;
    margin:0.5rem 0;
    border-radius: 10px;
    width: 90%;
    transition: .5s ease all;
}
.papers .year a{
    text-decoration: none;
    color: var(--secondary);
    transition: .5s ease all;
}
.papers .year:hover{    
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
}
.papers .year a:hover{
    color:var(--primary)
}
@media (min-width:600px) {
    .papers{
    display: grid;
    grid-template-columns: auto auto auto;
}
}
</style>